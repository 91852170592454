import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import './Footer.scss'

export default () => {
  return (
    <section className="footer-container has-background-primary has-padding-top-5 has-padding-bottom-5">
      <div className="container has-text-white">
        <div className="columns">
          <div className="column is-one-third">
            <img
              className="has-margin-bottom-3"
              width="250"
              src="/cp_logo_white.svg"
              alt="College Pulse Logo"
            />

            <div className="block">
              <span>
                <a href="https://www.facebook.com/PulseSurvey/">
                  <img
                    className="has-margin-right-3"
                    width="25"
                    src="/icons/facebook.svg"
                    alt="Facebook"
                  />
                </a>
              </span>
              <span>
                <a href="https://twitter.com/CollegeInsights">
                  <img
                    className="has-margin-right-3"
                    width="25"
                    src="/icons/twitter.svg"
                    alt="Twitter"
                  />
                </a>
              </span>
              <span>
                <a href="https://www.instagram.com/college_pulse/">
                  <img width="25" src="/icons/instagram.svg" alt="Instagram" />
                </a>
              </span>
            </div>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold has-margin-bottom-3">RESOURCES</h4>
            <ul className="footer-link-list">
              <li>
                <AnchorLink to="/#research" className="has-text-weight-light has-text-light">
                  Our Research
                </AnchorLink>
              </li>
              <li>
                <Link to="/blog/page/1" className="has-text-weight-light has-text-light">
                  Research Blog
                </Link>
              </li>
              <li>
                <Link to="/methodology" className="has-text-weight-light has-text-light">
                  Methodology
                </Link>
              </li>
              <li>
                <AnchorLink className="has-text-weight-light has-text-light" to="/#contactus">
                  Work With Us
                </AnchorLink>
              </li>
            </ul>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold has-margin-bottom-3">ABOUT US</h4>
            <ul className="footer-link-list">
              <li>
                <Link to="/about/" className="has-text-weight-light has-text-light">
                  About
                </Link>
              </li>
              <li>
                <a
                  className="has-text-weight-light has-text-light"
                  href="https://survey.collegepulse.com/jfe/form/SV_43mY8ilFgAsMoAe"
                >
                  Student Sign Up
                </a>
              </li>
              <li>
                <Link to="/careers/" className="has-text-weight-light has-text-light">
                  Careers
                </Link>
              </li>
              <li>
                <Link to="/becounted/" className="has-text-weight-light has-text-light">
                  Institutions: Be Counted
                </Link>
              </li>
            </ul>
          </div>
          <div className="column">
            <h4 className="has-text-weight-bold has-margin-bottom-3">CONTACT</h4>
            <ul className="footer-link-list">
              <AnchorLink to="/#contactus">
                <button className="button is-success">Get In Touch</button>
              </AnchorLink>
            </ul>
          </div>
        </div>
        <div className="level">
          <div className="level-left">
            <Link to="/terms">
              <div className="level-item has-text-white">Terms and Conditions</div>
            </Link>
          </div>
          <div className="level-right">
            <div className="level-item">
              ©{new Date().getFullYear()} College Pulse | All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
