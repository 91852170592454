import React from 'react'
import Navbar from '../Navbar/Navbar'
import StudentCTA from '../StudentCTA/StudentCTA'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet'

export default ({ children, noFooter, title }) => {
  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="business.business" />

        <meta
          name="description"
          content="Data-driven marketing and custom research solutions to help businesses and nonprofit organizations better understand and connect with American college students."
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="preconnect" href="https://www.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google.com" />
        <title>{title || 'College Pulse'}</title>
        <meta property="og:title" content={title || 'College Pulse'} />
        <meta
          name="keywords"
          content="college, pulse, data, research, students, custom, recruit, recruiting, inight, insights, survey, surveys, analytics, solutions, marketing, trends"
        />
      </Helmet>
      <Navbar />
      <main style={{ paddingTop: '64px' }}>{children}</main>
      {!noFooter && (
        <>
          <StudentCTA />
          <Footer />
        </>
      )}
    </React.Fragment>
  )
}
