import React, { useState, useEffect } from 'react'
import './Navbar.scss'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default () => {
  const [drawerDown, setDrawerDown] = useState(false)

  // Navbar changing on scrolling
  useEffect(() => {
    // The debounce function receives our function as a parameter
    const debounce = fn => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame)
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params)
        })
      }
    }

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY
    }

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener('scroll', debounce(storeScroll), { passive: true })

    // Update scroll position for first time
    storeScroll()
  }, [])

  return (
    <nav className="navbar is-fixed-top ">
      <div className="container">
        <div className={`navbar-brand ${drawerDown ? 'navbar-brand-drawer' : ''}`}>
          {!drawerDown && (
            <Link className="navbar-item" href="/">
              <img src="/cp_logo_dark_blue.svg" alt="Logo" />
            </Link>
          )}

          <a
            onClick={() => {
              setDrawerDown(prevState => !prevState)
            }}
            role="button"
            className={`navbar-burger burger ${drawerDown ? 'navbar-burger-drawer' : ''}`}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-center">
          <div className="navbar-item has-margin-right-4">
            <AnchorLink to="/#solutions">SOLUTIONS</AnchorLink>
          </div>
          <div className="navbar-item has-margin-right-4">
            <AnchorLink to="/#research">RESEARCH</AnchorLink>
          </div>
          <div className="navbar-item has-margin-right-4">
            <Link activeClassName="active" to="/methodology/">
              METHODOLOGY
            </Link>
          </div>
          <div className="navbar-item has-margin-right-4">
            <Link activeClassName="active" to="/blog/page/1">
              BLOG
            </Link>
          </div>
        </div>

        <div className="navbar-menu navbar-end">
          <div className="navbar-item">
            {/* NOTE: There is active in the name so we don't highlight it */}
            <a className="app-button active" href="https://survey.collegepulse.com/jfe/form/SV_43mY8ilFgAsMoAe">
              STUDENT SIGN UP
            </a>
          </div>
          <div className="navbar-item">
            <AnchorLink to="/#contactus">
              <button className="is-success button has-text-weight-bold is-centered">
                <span
                  className="has-padding-right-3 has-padding-left-3 is-centered"
                  style={{ fontSize: '0.8rem' }}
                >
                  GET IN TOUCH
                </span>
              </button>
            </AnchorLink>
          </div>
        </div>

        <section className={`navbar-drawer ${drawerDown ? 'navbar-drawer-down' : ''}`}>
          <ul>
            <li onClick={() => setDrawerDown(false)}>
              <AnchorLink to="/#solutions">SOLUTIONS</AnchorLink>
            </li>
            <li onClick={() => setDrawerDown(false)}>
              <AnchorLink to="/#research">RESEARCH</AnchorLink>
            </li>
            <li>
              <Link activeClassName="active" to="/methodology/">
                METHODOLOGY
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/blog/page/1">
                BLOG
              </Link>
            </li>
            <li>
              <a href="https://survey.collegepulse.com/jfe/form/SV_43mY8ilFgAsMoAe">STUDENT PORTAL</a>
            </li>
            <li onClick={() => setDrawerDown(false)}>
              <AnchorLink to="/#contactus">
                <button className="is-success button has-text-weight-bold">GET IN TOUCH</button>
              </AnchorLink>
            </li>
          </ul>
        </section>
      </div>
    </nav>
  )
}
