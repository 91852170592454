import React from 'react'
import Title from '../Title/Title'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import './StudentCTA.scss'

const StudentCTA = () => {
  const data = useStaticQuery(graphql`
    query {
      phone1: file(relativePath: { eq: "new_phone1.png" }) {
        ...fluidImage600
      }

      phone2: file(relativePath: { eq: "new_phone2.png" }) {
        ...fluidImage600
      }
    }
  `)

  return (
    <section className="student-cta-container">
      <Img className="left-phone" fluid={data.phone1.childImageSharp.fluid} alt="Phone right" />

      <div className="student-cta-content is-centered is-flex-column">
        <Title className="has-text-white is-centered">In College? Be Counted!</Title>
        <p className="has-text-white has-text-centered">
          Get accurate data about what your campus really thinks. Ask questions about what want to
          know about your campus, take surveys, analyze results, and anonymously discuss topics with
          other students while earning rewards.
        </p>
        <a className="studentCTA_Button" href="https://survey.collegepulse.com/jfe/form/SV_43mY8ilFgAsMoAe">
          <button className="has-background-white has-text-success has-text-weight-bold has-margin-top-4 has-outline has-padding-3 is-size-6 has-padding-left-5 has-padding-right-5">
            EXPLORE THE STUDENT PORTAL
          </button>
        </a>
      </div>

      <Img className="right-phone" fluid={data.phone2.childImageSharp.fluid} alt="Phone right" />
    </section>
  )
}

export default StudentCTA
